import React from "react";
import ReactDOM from "react-dom";

const redirects = {
  "localhost": "https://google.com",
	"redirect-to-dev.website.yhp-staging.com": "https://google.com",
  "www.youville-haussmannpark.com": "https://www.workatyhp.com",
  "youville-haussmannpark.com": "https://www.workatyhp.com",
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.redirectTo();
  }

	redirectTo = () => {
		const host = window.location.hostname;
		const redirectingTo = redirects[host];

		return {
      host: window.location.hostname,
      redirectingTo,			
    };    
  };

  componentDidMount() {
    if (this.state.redirectingTo) {
      window.location.assign(this.state.redirectingTo);
    }
  }

  renderMessage = () => {
    if (this.state.redirectingTo) {
      return `Redirecting to ${this.state.redirectingTo}...`;
    } else {
      return `${this.state.host} not found in redirects, add ${this.state.host} to redirects.. and deploy..`;
    }
  };
  render() {
    return <h1>{this.renderMessage()}</h1>;
  }
}

ReactDOM.render(<Header />, document.getElementById("root"));
